import React, { useState } from "react";
import "./ShortContact.scss";
import { useNavigate } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

const ShortContact = () => {
  let navigate = useNavigate();
  const Contact = () => {
    let path = `/reachout`;
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="contact-link">
        <p>Let's start a project together</p>
        <button onClick={Contact}>
          Work with me <FiArrowUpRight />
        </button>
      </div>
    </>
  );
};

export default ShortContact;
