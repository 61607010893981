import React from "react";
import "./Consultation.scss"; 

function Consultation() {
  return (
    <div className="consulatation-main" data-aos="flip-up"
    data-aos-duration="1500">
       <div className="consulatation">
          <h2 className="">Consultation</h2>
          <p>
            Choose any aspect related to the product that you would like to
            discuss, and I will offer my advice and suggestions on how to
            enhance the design, functionality and user experience of the
            product.
          </p>
          <a
            href="https://calendly.com/uxmurali/consulting"
            target="_blank" rel="noreferrer"
            className="schedule-call"
          >
            Schedule a call for free
          </a>
        </div>
    </div>
  );
}

export default Consultation;
