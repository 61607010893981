import React, { useState } from "react";
import "./Craft.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Project_card from "./Project-Card";
import ShortContact from "../ShortContact/ShortContact";
import { metaTitle, project_card } from "../Scripts/ConstData";
import banner_img from "../assets/projects-banner-img.png";
// import awsSdk from "aws-sdk";


// const config = {
//   accessKeyId: "AKIASKT655LZUJT33UPT",
//   secretAccessKey: "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",
//   region: "ap-south-1",
// };

const Craft =  () => {
  // const s3 = new awsSdk.S3({ config });
  
  // const getImage = async () => {
  //   const image = await s3.getObject({
  //     Bucket: "uxmurali",
  //     Key: "projects-banner-img.png",
  //   });

  //   return image.Body.toString();
  // };

  // const imageUrl = await getImage();

  return (
    <div className="">
      <Helmet>
        <title>Craft{metaTitle}</title>
      </Helmet>
      <Header />
      {/* < Cursor /> */}
      <div className="projects-container">
        <section
          className="banner-section"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <div className="bg-img">
            <img src={banner_img} alt="projects-banner-img" />
          </div>
          <div className="banner-content">
            <p className="banner-tittle">Customizable UI Kits</p>
            <p className="banner-subtext">
              Design your own look and feel with the components
            </p>
            <a
              href="http://gumroad.uxmurali.com/"
              target="_blank"
              rel="noreferrer"
            >
              Get it now
            </a>
          </div>
        </section>
        <section className="projects-main">
          <p
            className="projects-title"
            data-aos="fade-up-right"
            data-aos-duration="1000"
          >
            Craft
          </p>
          <p
            className="projects-desc"
            data-aos="fade-up-left"
            data-aos-duration="1000"
          >
            Designed highly polished mobile and web applications that boost
            business operations and deliver maximum value to users.
          </p>
        </section>
        <section className="">
          <Project_card Project_card={project_card} />
        </section>
      </div>
     <ShortContact />
      <Footer />
    </div>
  );
};
export default Craft;
