import React, { useState } from "react";
import "./About.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { FiArrowUpRight } from "react-icons/fi";
import Consultation from "../Consultation/Consultation";
import Profile_Image from "../assets/profile-img.png";
import Strategy from "../assets/strategy.svg";
import Research from "../assets/research.svg";
import UX_Design from "../assets/ux-design.svg";
import Visual_Design from "../assets/visual-design.svg";


const About = () => {
  const [expertise, setExpertise] = useState([
    "Product Design",
    "Journey Maps",
    "Persona’s",
    "Interaction Design",
    "Design Systems",
    "User Research",
    "Usability Testing",
    "Responsive & Adaptive Design",
    "Front-End Development",
  ]);
  const [tools, setTools] = useState([
    "Figma",
    "Sketch",
    "Adobe XD",
    "Invision",
    "Maze",
    "FigJam",
    "After Effects",
    "Premiere-Pro",
  ]);
  const [interests, setInterests] = useState([
    "Writing",
    "Video Editing",
    "Motorcycle Ride",
    "Coding",
  ]);



  return (
    <div className="">
      <Helmet>
      <title>Murali | Product Designer</title>
      </Helmet>
      <Header />
      
      <section className="section-one">
          <div className="main-hero">
            <div className="greet">
              <span className="" data-aos="fade-up" data-aos-duration="400">
                Hello, I'm Murali a
              </span>
              <p
                className="main-title"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Creative
              </p>
            </div>
            <div className="job-title">
              <span className="" data-aos="fade-up" data-aos-duration="1200">
                product
              </span>
              <div className="align-links">
                <p
                  className="main-title"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  Designer
                </p>
                <div
                  className="name-seperator"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                ></div>
                <ul className="social-links">
                  <li className="animate__animated animate__jello">
                    <a
                      href="https://www.behance.net/muralilucky"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Behance
                    </a>
                  </li>
                  <li className="animate__animated animate__jello">
                    <a
                      href="https://www.linkedin.com/in/murali-lucky/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li className="animate__animated animate__jello">
                    <a
                      href="https://github.com/Murali-Dunna"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GitHub
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>


      <section className="about-section-one">
        <div className="about-hero" data-aos="fade-up" data-aos-duration="1000">
          “A self-taught and passionate Product Designer based in Bangalore who
          strives to create valuable and meaningful experiences through design
          since 2019.
        </div>
      </section>

      <section className="about-section-two">
        <div
          className="profile-img"
          data-aos="fade-up-right"
          data-aos-duration="1000"
        >
          <img src={Profile_Image}/>
        </div>
        <div
          className="section-two-container"
          data-aos="fade-up-left"
          data-aos-duration="1000"
        >
          <div className="profile-content">
            <p className="greeting">My Story</p>
            <p className="bio">
              Hey there, I'm Murali! I've been working as a Product Designer for
              a few years now, and I absolutely love what I do. I strive to
              create meaningful experiences that make people's lives better.
            </p>
            <p className="bio">
              I'm really particular about the little details in design - the
              things that maybe people don't even notice, but that make all the
              difference. Like micro animations and interactions - they might
              seem small, but they can really elevate a user's experience and
              make them feel more connected to the product. And of course,
              strategy and research are huge parts of the process too. You gotta
              know your users and understand their needs in order to create
              something that truly resonates with them.
            </p>
            <a href="https://cv.uxmurali.com/" target="_blank" rel="noreferrer" className="bio-link" >
              Check out my resume <FiArrowUpRight />
            </a>
          </div>
          <div className="profile-content">
            <p className="bio">
              Along with my design expertise, I also have experience in
              developing scalable websites from scratch. This allows me to
              seamlessly integrate design and functionality to create optimal
              product performance. My education in B.E Information Science and
              experience as a frontend developer provide me with the technical
              know-how to ensure that every product I design is as functional as
              it is visually appealing.
            </p>

            <a
              href="https://github.com/Murali-Dunna"
              target="_blank" rel="noreferrer"
              className="bio-link"
            >
              Read the code behind this website <FiArrowUpRight />
            </a>
          </div>
          <div className="profile-content">
            <p className="bio">
              Apart from designing, I like to explore new hobbies and passions.
              One of my favorite hobbies is coding - it's a great way to dive
              deep into the technical aspects of design. I also enjoy going for
              long rides to clear my head, experimenting with video editing, and
              writing blogs about design-related topics. Lastly, I love keeping
              up with the latest technologies and exploring how they can be used
              to enhance the design process.
            </p>
          </div>
          <div className="profile-content">
            <div className="profile-stats-main">
              <div className="profile-stats">
                <p className="stats-num">30+</p>
                <p className="stats-info">projects</p>
              </div>
              <div className="profile-stats">
                <p className="stats-num">16+</p>
                <p className="stats-info">clients</p>
              </div>
              <div className="profile-stats">
                <p className="stats-num">99%</p>
                <p className="stats-info">satisfaction level</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="section-two">
          <div className="process-main">
            <div
              className="approach-tittle"
              data-aos="fade-up"
              data-aos-duration="800"
            >
            My Approach

            </div>


            <div className="process-cards">
              <div
                className="process-card-1"
                data-aos="fade-up-right"
                data-aos-duration="1200"
              >
                <img className="" src={Strategy} alt="Strategy" />
                <h2 className="">Strategy</h2>
                <p className="">User Goals</p>
                <p className="">Business Goals</p>
                <p className="">Scope</p>
              </div>
              <div
                className="process-card-2"
                data-aos="fade-up-right"
                data-aos-duration="800"
              >
                <img className="" src={Research} alt="Research" />
                <h2 className="">Research</h2>
                <p className="">Business analysis</p>
                <p className="">Competitive analysis</p>
                <p className="">User journey</p>
              </div>
              <div
                className="process-card-3"
                data-aos="fade-up-left"
                data-aos-duration="800"
              >
                <img className="" src={UX_Design} alt="UX Design" />
                <h2 className="">UX Design</h2>
                <p className="">Information Architecture</p>
                <p className="">Wireframes</p>
                <p className="">Usability Testing</p>
              </div>
              <div
                className="process-card-4"
                data-aos="fade-up-left"
                data-aos-duration="1200"
              >
                <img className="" src={Visual_Design} alt="Visual Design" />
                <h2 className="">Visual Design</h2>
                <p className="">Design systems</p>
                <p className="">HiFi Prototype</p>
                <p className="">Dev hands-off</p>
              </div>
            </div>
          </div>
        </section>


      <section className="about-section-three">
        <div className="section-three-main">
          <div className="col-one" data-aos="fade-up" data-aos-duration="1000">
            <h2 className="">Area of expertise</h2>
            <ul>
              {expertise.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="col-two" data-aos="fade-up" data-aos-duration="1500">
            <h2 className="">Current tools</h2>
            <ul>
              {tools.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div
            className="col-three"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <h2 className="">Interests</h2>
            <ul>
              {interests.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <Consultation />
      <Footer />
    </div>
  );
};
export default About;
