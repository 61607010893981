import React, { useState, useEffect } from "react";
import "./Footer.scss";
import { AiOutlineGithub } from "react-icons/ai";
import { FaLinkedinIn, FaBehance, FaDribbble } from "react-icons/fa";
import { ImQuotesLeft } from "react-icons/im";

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);


  return (
    <div className="footer-container">
      <section className="footer-top">
        <div className="quote-section">
          <ImQuotesLeft className="quote-icon" />
          <p className="quote">
            If you think good design is expensive, you should look at the cost
            of bad design.
          </p>
          <span className="">-Ralf Speth</span>
        </div>
        <div className="footer-links-header">
          <p>Follow my work</p>
          <ul className="footer-links">
            <li>
              <a
                href="https://www.behance.net/muralilucky"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaBehance className="footer-social-icons" />
              </a>
            </li>
            <li>
              <a
                href="https://dribbble.com/Murali-18/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDribbble className="footer-social-icons" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/murali-lucky/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn className="footer-social-icons" />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/Murali-Dunna"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineGithub className="footer-social-icons" />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <span className="divider" />
      <section className="footer-bottom">
        <p>Design and Code by Murali</p>
        <p className="cprt-text"> &copy; {year}, Murali - Product Designer</p>
      </section>

    </div>
  );
};
export default Footer;
