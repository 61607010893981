import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../assets/404-Error.svg";
import "./PageNotFound.scss";

const PageNotFound = () => {
  let navigate = useNavigate();
  const returnHome = () => {
    let path = `/`;
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <div className="error-main">
      <img className="404-img" src={Page} />
      <button onClick={returnHome}>Go to home</button>
    </div>
  );
};
export default PageNotFound;
