import "./Header.scss";
import header_logo from "../assets/Logo.svg";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="">
      <div className="header-container">
        <div className="logo">
          <img className="" src={header_logo} alt="logo" />
        </div>
        <ul className="nav-links">
          <li>
            <NavLink
              to="/"
              activeclassname="active"
              className="menu-links"
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/craft"
              activeclassname="active"
              className="menu-links"
            >
              Craft
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reachout"
              activeclassname="active"
              className="menu-links"
            >
              Reach out
            </NavLink>
          </li>
        </ul>
        {/* <MdDarkMode className="theme-icon" /> */}
      </div>
    </div>
  );
};
export default Header;
