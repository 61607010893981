import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Consultation from "../Consultation/Consultation";
import { Helmet } from "react-helmet";
import { metaTitle } from "../Scripts/ConstData";
import "./Contact.scss";
import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";


const Contact = () => {
  return (
    <div className="">
      <Helmet>
        <title>Reach out{metaTitle}</title>
      </Helmet>
      <Header />
      {/* < Cursor /> */}

      <section className="contact-container">
        <Consultation />
        <div className="contact-main">
          <p data-aos="fade-right" data-aos-duration="1000" >Say Hello, Let's Collaborate</p>
          <div className="contact-main-content">
            <div className="form-container"  data-aos="fade-up-right"
          data-aos-duration="1000">
              <form
                className="contact-form"
                action="https://formsubmit.co/muralidunna4@gmail.com"
                method="POST"
              >
                <div className="">
                  <label>What’s your name?</label>
                  <input
                    type="text"
                    placeholder="ex: John Doe*"
                    className="inp-field"
                    name="Name"
                    required
                  />
                  <input
                    type="hidden"
                    name="_next"
                    value="uxmurali.vercel.app"
                  />
                  <input
                    type="hidden"
                    name="_subject"
                    value="New enquiry from your website!"
                  />
                  <input type="hidden" name="_captcha" value="false" />
                </div>
                <span></span>

                <div className="">
                  <label>What’s your email?</label>
                  <input
                    type="text"
                    placeholder="ex: john@doe.com*"
                    className="inp-field"
                    name="Email"
                    required
                  />
                </div>
                <span></span>

                <div className="">
                  <label>What services are you looking for?</label>
                  <input
                    type="text"
                    placeholder="ex: UX Design, Web Development etc*"
                    className="inp-field"
                    name="Services looking for"
                    required
                  />
                </div>
                <span></span>

                <div className="">
                  <label>Your Message!</label>
                  <input
                    type="text"
                    placeholder="ex: Can you help me with..*"
                    className="inp-field"
                    name="Message"
                  />
                </div>

                <span></span>
                <div className="">
                  <input
                    type="Submit"
                    value="Send it!"
                    className="btn-submit"
                  />
                </div>
              </form>
            </div>

            <div className="contact-right-section"  data-aos="fade-up-left"
          data-aos-duration="1000">
              <div className="contact-info">
                <div className="contact-details">
                  <h6>Contact details</h6>
                  <a href="mailto:muralidunna4@gmail.com" target="_blank">
                    muralidunna4@gmail.com <FiArrowUpRight />
                  </a>
                  <a href="tel:+91 8073308410" target="_blank">
                    +91 8073308410 <FiArrowUpRight />
                  </a>
                </div>

                <div className="contact-details">
                  <h6>Location</h6>
                  <a
                    href="https://goo.gl/maps/SQdNf1LXVHcFxmxj6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bangalore, India
                  </a>
                </div>

                <div className="social-details">
                  <h6>Social</h6>
                  <div className="social-icons">
                    <a
                      href="https://www.linkedin.com/in/murali-lucky/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaLinkedinIn />
                    </a>
                    <a
                      href="https://twitter.com/_murali_lucky_"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaTwitter />
                    </a>
                    <a
                      href="https://www.instagram.com/_lucky.2358_/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Contact;
