


//Projects images
import srichakra from "../assets/project-cover/Srichakra.png";
import square_it from "../assets/project-cover/Square-it.png";
import doctor_app_wireframe from "../assets/project-cover/Medical-wireframe.png";
import bake_shop from "../assets/project-cover/Bake-shop.png";
import parking_app from "../assets/project-cover/Parking-app.png";
import hullo from "../assets/project-cover/Hullo.png";
import pricing_meter from "../assets/project-cover/Pricing-meter.png";
import real_estate_app from "../assets/project-cover/Real-estate-app.png";
import gamzinn from "../assets/project-cover/Gamzinn.png";
import gurunanda from "../assets/project-cover/Gurunanda.png";
import cab_booking from "../assets/project-cover/Cab-booking.png";
import pet_store from "../assets/project-cover/Pet-Store.png";
import fresh_prints from "../assets/project-cover/Fresh-prints.png";


//Services images
import service1 from "../assets/project-cover/Square-it.png";

//Meta tittle for the website
export const metaTitle = " | Murali";

//Projects images and data
export const project_card = [
  {
    tittle: "Srichakra",
    img: srichakra,
    year: "2022",
    link: "https://www.behance.net/gallery/164206269/Srichakra-REDESIGN",
  },
  {
    tittle: "Square It.",
    img: square_it,
    year: "2022",
    link: "https://www.behance.net/gallery/156193233/SQUARE-IT",
  },
  {
    tittle: "Bake Shop",
    img: bake_shop,
    year: "2021",
    link: "https://www.behance.net/gallery/120869747/Online-Bakery",
  },
  {
    tittle: "Doctor Consultation Wireframe",
    img: doctor_app_wireframe,
    year: "2021",
    link: "https://www.behance.net/gallery/125161039/Medical-App-Wireframe",
  },
  {
    tittle: "FreshPrints - Funnel design",
    img: fresh_prints,
    year: "2022",
    link: "https://www.behance.net/gallery/123747361/Funnel-Desgin",
  },
  {
    tittle: "Parking App",
    img: parking_app,
    year: "2021",
    link: "https://www.behance.net/gallery/123744839/Parking-App",
  },
  {
    tittle: "Hullo",
    img: hullo,
    year: "2021",
    link: "https://www.behance.net/gallery/133751119/New-generation-Chat-App",
  },
  {
    tittle: "Guru Nanda",
    img: gurunanda,
    year: "2021",
    link: "https://www.behance.net/gallery/133750737/Home-essentials",
  },
  {
    tittle: "Pricing Meter Website",
    img: pricing_meter,
    year: "2020",
    link: "https://www.behance.net/gallery/125160429/Real-Estate-Website-Minimal-UI",
  },
  {
    tittle: "Real Estate Mobile App",
    img: real_estate_app,
    year: "2020",
    link: "https://www.behance.net/gallery/120868823/Real-Estate",
  },
  {
    tittle: "GamzInn",
    img: gamzinn,
    year: "2020",
    link: "https://www.behance.net/gallery/133750579/Website-for-games",
  },
  {
    tittle: "Cab Booking App",
    img: cab_booking,
    year: "2020",
    link: "https://www.behance.net/gallery/123746365/Cab-Booking",
  },
  {
    tittle: "Pet Store Landing page",
    img: pet_store,
    year: "2020",
    link: "https://www.behance.net/gallery/120869035/Pet-Store",
  },
];

//Services data
export const service_card = [
  {
    id: "01",
    service_name: "Mobile App Design",
    service_img: service1,
    service_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra lacus montes, in. Sed at ullamcorper",
    link: "",
  },
  {
    id: "01",
    service_name: "Mobile App Design",
    service_img: service1,
    service_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra lacus montes, in. Sed at ullamcorper",
    link: "",
  },
  
];

