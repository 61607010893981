const Project_card = (props) => {
  return (
    <div className="projects-cards-main " >
      {props.Project_card.map((value, index) => (
        <a className="project-card animate__animated animate__fadeInUp" key={index} href={value.link} target="_blank">
          <div className="img-container">
            <img className="project-img" src={value.img} />
          </div>
          <div className="project-details">
            <p className="project-card-title">
              {value.tittle}
              <span className="year"> — {value.year}</span>
            </p>
          </div>
        </a>
      ))}
    </div>
  );
};
export default Project_card;
