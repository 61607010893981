import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Craft from "../Craft/Craft";
import About from "../About/About";
import Contact from "../Contact/Contact";
import PageNotFound from "../PageNotFound/PageNotFound";
import ScrollToTop from "../Scripts/ScrollToTop";


const Routers = () => {
   
  return (
    <div>
      <BrowserRouter basename="/">
        <Routes>
          {/* <Route exact path="/" element={<Comingsoon />} /> */}
          <Route exact path="/" element={<About />} />
          <Route exact path="/craft" element={<Craft />} />
          <Route exact path="/reachout" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
};

export default Routers;
