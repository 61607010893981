import React from "react";
import "./Loading.scss"; // Import the CSS file for the loading animation

function Loading() {
  return (
    <div className="loading-container">
      <div class="spinner">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
    </div>
  );
}

export default Loading;

