import "./App.css";
import Routers from "./Routers/Router";
import "animate.css";
import Loading from "./Pre website/Loading";
import BurstAnimation from "./Scripts/BurstAnimation";
// import Cursor from "./Cursor/Cursor";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { inject } from "@vercel/analytics";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";

function App() {
  
  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  });
  inject();
  AOS.init();
  const [isLoading, setIsLoading] = useState(true);
  const [slowMo, setSlowMo] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500); // Simulate a 2-second loading time
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <BurstAnimation
      onClick={() => {
        setSlowMo(!slowMo);
      }}
    >
      {/* <Cursor /> */}
      <ReactLenis root>
        <div className="App">
          <div className="g-bg">
            <div></div>
          </div>
          <Routers />
        </div>
      </ReactLenis>
    </BurstAnimation>
  );
}

export default App;
